import React from 'react';
import { H2, CellText } from '@increasecard/typed-components';
import styled from 'styled-components';
import { Container } from '../components/Container';
import { MEDIUM } from '../breakpoints';

const SectionWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colorsNew.gray.white};
  justify-content: center;
`;

const ContentWrapper = styled(Container)`
  display: grid;
  grid-template-columns: 100%;
  align-items: start;
  grid-column-gap: 48px;
  grid-row-gap: 32px;
  padding-top: 80px;
  padding-bottom: 80px;

  @media (min-width: ${MEDIUM}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Benefit = styled.div`
  background: ${({ theme, variant }) =>
    variant === 'enterprise'
      ? `linear-gradient(180deg, ${theme.colorsNew.increase.dark} 0%, ${theme.colorsNew.gray.black} 100%)`
      : theme.colorsNew.increase.dark};
  box-shadow: ${({ theme }) => theme.shadows.rest};
  border-radius: 12px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 64px 24px 12px;
  text-align: center;
  position: relative;
  margin-top: 52px;

  @media (min-width: ${MEDIUM}) {
    padding: 64px 24px 12px;
  }
`;

const BenefitTitle = styled(H2)`
  color: ${({ theme }) => theme.colorsNew.increase.accent};
  margin-bottom: 8px;
`;

const BenefitText = styled(CellText)`
  max-width: 16em;
  margin: 0 auto;
`;

const BenefitImageWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: calc(50% - 52px);
  top: -52px;
  border-radius: 50%;
  height: 104px;
  width: 104px;
  background: ${({ theme, variant }) =>
    variant === 'enterprise'
      ? theme.colorsNew.increase.accent
      : theme.colorsNew.increase.regular};
  color: white;
`;

export function GenericBenefitsSection({ benefits, variant }) {
  return (
    <SectionWrapper>
      <ContentWrapper>
        {benefits.map(b => (
          <Benefit key={b.id} variant={variant}>
            <BenefitImageWrapper variant={variant}>
              <img alt="" src={b.image.imageSharp.publicURL} height="64" />
            </BenefitImageWrapper>
            <BenefitTitle dangerouslySetInnerHTML={{ __html: b.name }} />
            <BenefitText dangerouslySetInnerHTML={{ __html: b.description }} />
          </Benefit>
        ))}
      </ContentWrapper>
    </SectionWrapper>
  );
}
