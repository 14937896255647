import React from 'react';
import { Container as BaseContainer } from '../components/Container';
import styled from 'styled-components';
import { H2 } from '@increasecard/typed-components';
import { CTAButton } from '../components/CTAButton';
import GatsbyImage from 'gatsby-image';
import { MEDIUM } from '../breakpoints';
import { SuperHeader } from '../components/Typography';

const Container = styled(BaseContainer)`
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
`;

const Logos = styled.div`
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 120px);
  grid-template-rows: repeat(auto-fit, 48px);

  @media (min-width: ${MEDIUM}) {
    grid-row-gap: 16px;
    grid-template-columns: repeat(auto-fit, 120px);
  }
`;

const LogosGroup = styled.div`
  width: 100%;

  > *:not(:first-child) {
    margin-top: 32px;
  }

  margin-bottom: 32px;

  @media (min-width: ${MEDIUM}) {
    display: grid;
    grid-row-gap: 16px;
    grid-auto-flow: row;
    flex-direction: column;
    grid-template-rows: repeat(auto-fit, 48px);

    > *:not(:first-child) {
      margin-top: 0;
    }
  }
`;

export function ClientsLogosSection({ title, subtitle, cta, rows }) {
  return (
    <Container>
      <SuperHeader>{title}</SuperHeader>
      <H2 weight="normal" style={{ margin: '8px auto 32px' }}>
        {subtitle}
      </H2>
      <LogosGroup>
        {rows.map(({ clients }, index) => (
          <Logos key={index}>
            {clients.map(({ name, logo }) => (
              <GatsbyImage
                key={name}
                {...(logo.childImageSharp || logo.imageSharp.childImageSharp)}
                style={{ maxHeight: '100%' }}
              />
            ))}
          </Logos>
        ))}
      </LogosGroup>
      {cta && <CTAButton {...cta} />}
    </Container>
  );
}
