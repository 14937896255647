import React from 'react';
import styled from 'styled-components';
import { BackgroundBase } from './BackgroundBase';
import { RoundedSquare } from './shapes/RoundedSquare';

const Intersect1 = styled.div`
  position: absolute;
  height: 167.22px;
  bottom: -100px;
  left: -10px;
  right: -10px;

  background: ${({ theme }) => theme.colorsNew.gray.blackSLight};
  opacity: 0.6;
  transform: rotate(-3deg);

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    transform: rotate(3deg);
    bottom: -120px;
  }
`;

const Intersect2 = styled(RoundedSquare).attrs({
  size: '486px',
  bgColor: 'hsla(0, 0%, 50%, 0.5)',
})`
  position: absolute;
  right: -360px;
  top: -100px;
  mix-blend-mode: multiply;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    right: 40px;
    top: -180px;
  }
`;

const Gradient = styled.div`
  background: linear-gradient(180deg, #6c7174 0%, rgba(24, 32, 38, 0.9) 68.23%);
  mix-blend-mode: overlay;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

export function EnterpriseBackground({ children }) {
  return (
    <BackgroundBase
      baseBackground={theme => theme.colorsNew.product.dark}
      renderBackground={_ => (
        <Gradient>
          <Intersect1 /> <Intersect2 />
        </Gradient>
      )}
    >
      {children}
    </BackgroundBase>
  );
}
