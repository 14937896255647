import React from 'react';
import styled, { css } from 'styled-components';
import { MEDIUM } from '../breakpoints';
import { SmallRoundedSquare } from './shapes/RoundedSquare';
import { BackgroundBase } from './BackgroundBase';

const CrossingLine = styled.div`
  position: absolute;
  width: 80px;
  height: 1300px;
  left: 420px;
  top: 300px;
  background: ${({ theme, variant }) =>
    variant === 'enterpriseAccent'
      ? theme.colorsNew.product.accent
      : theme.colorsNew.product.regular};
  opacity: ${({ variant }) => (variant === 'enterprise' ? 0.2 : 0.05)};
  transform: rotate(-60deg);

  @media (min-width: ${MEDIUM}) {
    width: 160px;
    top: -160px;
  }
`;

const ProductColorSmallSquare = styled(SmallRoundedSquare).attrs(
  ({ variant }) => ({
    bgColor: theme =>
      variant === 'enterpriseAccent'
        ? theme.colorsNew.product.accent
        : theme.colorsNew.product.regular,
  })
)`
  position: absolute;
  top: 20px;
  ${({ layout }) =>
    layout === 'imageLeftTextRight'
      ? css`
          right: 15%;
          bottom: 60vw;
        `
      : css`
          left: 80%;
        `}

  @media (min-width: ${MEDIUM}) {
    top: 20px;
    left: 80%;
  }
`;

export function ProductHeroBackground({ children, variant, layout }) {
  return (
    <BackgroundBase
      baseBackground={theme => {
        switch (variant) {
          case 'enterprise':
          case 'enterpriseAccent':
            return theme.colorsNew.gray.black;
          default:
            return theme.colorsNew.gray.white;
        }
      }}
      variant={variant}
      renderBackground={variant => (
        <>
          <CrossingLine variant={variant} />
          <ProductColorSmallSquare layout={layout} variant={variant} />
        </>
      )}
    >
      {children}
    </BackgroundBase>
  );
}
