import React from 'react';
import { EnterpriseBackground } from '../backgrounds/EnterpriseBackground';
import { Container as BaseContainer } from '../components/Container';
import styled from 'styled-components';
import { Paragraph } from '@increasecard/typed-components';
import { CTAButton } from '../components/CTAButton';
import { MEDIUM } from '../breakpoints';
import { ENTERPRISE_ENABLED } from '../flags';

const Container = styled(BaseContainer)`
  padding-top: 104px;
  padding-bottom: 104px;
  color: ${({ theme }) => theme.colorsNew.gray.white};
  text-align: center;
`;

const ProductName = styled.strong`
  font-weight: bold;
  color: ${({ theme, variant }) =>
    variant === 'accent'
      ? theme.colorsNew.product.accent
      : theme.colorsNew.product.regular};
`;

const Enterprise = styled.span`
  font-style: italic;
`;

const EnterprisePreTitle = styled.h1`
  ${({ theme }) => theme.typography.H2}

  margin-bottom: 24px;

  @media (min-width: ${MEDIUM}) {
    ${({ theme }) => theme.typography.H1}
    line-height: 40px;
    margin-bottom: 0;
  }
`;

const EnterpriseTitle = styled.h1`
  ${({ theme }) => theme.typography.superHeader}
  font-weight: bold;

  @media (min-width: ${MEDIUM}) {
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
  }
`;

const Description = styled(Paragraph)`
  max-width: 32em;
  margin: 0 auto 64px;

  @media (min-width: ${MEDIUM}) {
    margin: 0 auto 32px;
  }
`;

export function EnterpriseSection({ product, description, cta, variant }) {
  if (!ENTERPRISE_ENABLED) return null;
  return (
    <EnterpriseBackground baseColor={theme => theme.colorsNew.pay.dark}>
      <Container>
        <EnterprisePreTitle weight="normal">Conoce</EnterprisePreTitle>
        <EnterpriseTitle style={{ marginBottom: '8px' }}>
          <ProductName variant={variant}>
            Increase {product && product.name}
          </ProductName>{' '}
          <Enterprise>Enterprise</Enterprise>
        </EnterpriseTitle>
        <Description>{description}</Description>
        <CTAButton
          style={{ width: 'max-content' }}
          type={variant === 'accent' ? variant : undefined}
          {...cta}
        />
      </Container>
    </EnterpriseBackground>
  );
}
