import React from 'react';
import styled, { css } from 'styled-components';
import { H3, ThemeProvider } from '@increasecard/typed-components';
import { CTAButton } from '../components/CTAButton';
import { ProductHeroBackground } from '../backgrounds/ProductHeroBackground';
import { Container } from '../components/Container';
import { Grid } from '../components/Grid';
import { MEDIUM } from '../breakpoints';
import { DisplayTitle } from '../components/DisplayTitle';
import { H1 } from '../components/Typography';
import { ResponsiveImage } from '../components/ResponsiveImage';

const HeroContainer = styled(Container)`
  padding-top: 36px;
  padding-bottom: 36px;
  @media (min-width: ${MEDIUM}) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`;

const ImageWrapper = styled.div`
  display: none;
  width: 90vw;
  margin-right: auto;
  margin-top: 32px;

  img {
    width: 100%;
  }

  @media (min-width: ${MEDIUM}) {
    display: unset;
    position: absolute;
    margin-top: 80px;
    top: -80px;
    height: calc(100% + 22px);
    bottom: 0;
    ${({ layout }) =>
      layout === 'imageLeftTextRight'
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `}
    width: auto;
    display: flex;
    align-items: start;

    img {
      width: auto;
    }
  }
`;

const CTAGroup = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 8px;

  @media (min-width: ${MEDIUM}) {
    grid-template-columns: repeat(2, max-content);
  }
`;

const TextContainer = styled.div`
  grid-column: span 2 / span 2;
  @media (min-width: ${MEDIUM}) {
    grid-column: ${({ layout }) => (layout === 'imageLeftTextRight' ? 6 : 1)} /
      span 3;
  }
`;

export function GenericHeroSection({
  title,
  subtitle,
  description,
  image,
  primaryCTA,
  secondaryCTA,
  variant,
  layout,
  productName,
}) {
  const imageSharp = image.childImageSharp || image.imageSharp.childImageSharp;
  return (
    <ThemeProvider productName={productName}>
      <ProductHeroBackground variant={variant} layout={layout}>
        <ImageWrapper layout={layout} style={{ maxWidth: '60%' }}>
          {imageSharp && (
            <ResponsiveImage
              {...imageSharp.fluid}
              pictureStyle={{ marginTop: 'auto', marginBottom: '0' }}
            />
          )}
        </ImageWrapper>
        <HeroContainer>
          <Grid>
            <TextContainer layout={layout}>
              <DisplayTitle dangerouslySetInnerHTML={{ __html: title }} />
              <H1
                as="h2"
                dangerouslySetInnerHTML={{ __html: subtitle }}
                weight="normal"
              />
              <H3
                dangerouslySetInnerHTML={{ __html: description }}
                weight="normal"
                style={{ marginBottom: '48px' }}
              />
              <CTAGroup>
                {primaryCTA && <CTAButton {...primaryCTA} />}
                {secondaryCTA && (
                  <CTAButton {...secondaryCTA} type="product-outline" />
                )}
              </CTAGroup>
            </TextContainer>
          </Grid>
        </HeroContainer>
      </ProductHeroBackground>
    </ThemeProvider>
  );
}
