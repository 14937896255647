import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { H3, Label } from '@increasecard/typed-components';
import { CTAButton } from '../components/CTAButton';
import { Grid } from '../components/Grid';
import { SMALL } from '../breakpoints';
import { Container } from '../components/Container';
import { H1 } from '../components/Typography';

const FeatureDescriptionContainer = styled.div``;
const ImageWrapper = styled.div``;
const Wrapper = styled.div`
  background: ${({ backgroundVariant }) => {
    switch (backgroundVariant) {
      case 'enterpriseHome':
        return `linear-gradient(180deg, rgba(13, 98, 76, 0.4) 6.25%, rgba(24, 32, 38, 0.4) 82.29%)`;
      case 'increaseHome':
        return `linear-gradient(180deg, rgba(13, 98, 76, 0.2) 0%, rgba(255, 255, 255, 0) 100%)`;
      default:
        return 'transparent';
    }
  }};
  padding-top: 64px;
`;

const FeatureSectionWrapper = styled(Grid)`
  :first-child {
    margin-top: 0;
  }
  margin: 64px 0;
  align-items: left;
  grid-template-areas:
    'image image'
    'description description'
    'description description';
  justify-content: center;
  align-items: center;

  > ${ImageWrapper} {
    grid-area: image;
    margin: 32px 0 16px;
  }

  > ${FeatureDescriptionContainer} {
    grid-area: description;
  }

  @media (min-width: ${SMALL}) {
    > ${ImageWrapper} {
      margin: 0;
    }

    &:nth-child(2n) {
      grid-template-areas:
        'description description description image image image image image'
        'description description description image image image image image';
    }
    &:nth-child(2n -1) {
      grid-template-areas:
        'image image image image image description description description'
        'image image image image image description description description';
    }
  }
`;

const FeatureNameLabel = styled(Label)`
  color: ${({ theme }) => theme.colorsNew.product.regular};
`;

export function FeaturesSection({ backgroundVariant, features }) {
  return (
    <Wrapper backgroundVariant={backgroundVariant}>
      <Container>
        {features.map(
          ({ id, name, title, description, ctaText, ctaUrl, image }) => (
            <FeatureSectionWrapper key={id}>
              <FeatureDescriptionContainer>
                {name && <FeatureNameLabel as="p">/ {name}</FeatureNameLabel>}
                <H1
                  weight="normal"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                <H3
                  dangerouslySetInnerHTML={{ __html: description }}
                  weight="normal"
                  style={{ marginBottom: '24px' }}
                />
                {ctaUrl && <CTAButton href={ctaUrl} text={ctaText} />}
              </FeatureDescriptionContainer>
              <ImageWrapper>
                {image && <Img alt="" {...image.imageSharp.childImageSharp} />}
              </ImageWrapper>
            </FeatureSectionWrapper>
          )
        )}
      </Container>
    </Wrapper>
  );
}
